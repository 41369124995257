<template>
  <div class="box-1">
    <div class="container" style="margin-bottom: 1rem">
      <el-row>
        <content-title :nav="nav"></content-title>
      </el-row>
    </div>
    <el-table :data="list">
      <el-table-column label="ID" prop="id" width="100"></el-table-column>
      <el-table-column
        label="课程名"
        prop="map.userAppointmentInfo.title"
      ></el-table-column>
      <el-table-column
        label="用户"
        prop="map.userAppointmentInfo.map.userinfo.nick_name"
      ></el-table-column>
      <el-table-column label="说明" width="150">
        <template slot-scope="{row}">
          <el-tooltip class="item" effect="dark" :content="row.remark" placement="bottom">
     <div class="remark">
            {{row.remark}}
          </div>
    </el-tooltip>
          
        </template>
      </el-table-column>
      <!-- <el-table-column label="类型" prop="type"></el-table-column> -->
      <el-table-column
        label="开始时间"
        prop="change_starttime"
      ></el-table-column>
      <el-table-column label="结束时间" prop="change_endtime"></el-table-column>
      <el-table-column label="状态" width="100px">
        <template slot-scope="{ row }">
          <div v-if="row.handle == 1" style="color: green">已同意</div>
          <div v-if="row.handle == 2" style="color: red">已拒绝</div>
          <div v-if="row.handle == 3">待审核</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <div v-if="row.handle == 3">
            <el-button type="primary" @click="confrim(row, 1)" size="small">同意</el-button>
            <el-button type="danger" @click="confrim(row, 2)" size="small">拒绝</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <paging-fy
      @currentPageChange="currentPageChange"
      :currentPage="currentPage"
      :total="total"
    ></paging-fy>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: {
        firstNav: "课程管理",
        secondNav: "课程时间审核",
      },
      list: [],
      total: 0,
      currentPage: 1,
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    updateTime(row) {
      this.timeshow = true;
      this.curCourse = row;
    },

    confrim(row, i) {
      let url = "/user/masterNotice/udpateAgree";
      let data = {
        id: row.id,
        handle: i,
        handler_id:this.adminInfo.id,
				handler_name:this.adminInfo.name
      };
      this.$axios({ url, data, method: "post" }).then((res) => {
        this.$message({ message: res.data.message });
        this.getlist();
      });
    },
    getlist() {
      let params = {
        currentPage: this.currentPage,
        pageSize: 10,
        type:'change_time'
      };
      let url = "/user/masterNotice/queryManagerListPage";
      this.$axios({ url, params, method: "get" }).then((res) => {
        this.list = res.data.data.rows;
        this.total = res.data.data.total;
        // this.$message({message:res.data.message})
      });
    },
    currentPageChange(v) {
      this.currentPage = v;
      this.getlist();
    },
  },
};
</script>

<style >
.box-1{
  background-color: #FFFFFF;
}
.remark{
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(128, 163, 229);
}
</style>
